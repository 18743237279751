import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {ActionSheet, Field, CellGroup, Button, Toast, Dialog, ConfigProvider} from "vant";
import 'vant/lib/index.css';
import http from './utils/http';

const app = createApp(App)
app.config.globalProperties.$http = http;
app.use(store)
    .use(router)
    .use(ActionSheet)
    .use(Field)
    .use(CellGroup)
    .use(Button)
    .use(Toast)
    .use(Dialog)
    .use(ConfigProvider)
    .mount('#app')
