<template>
  <router-view/>
</template>

<style lang="less">
body {
  background-color: #000000;
}

html {
  height: 100%
}
</style>
