class StringUtils {

  /**
   * 替换中间为省略号
   *
   * @param str
   * @returns {*|string}
   */
  sliceString(str) {
    if (str.length > 10) {
      const firstFive = str.slice(0, 4);
      const lastFour = str.slice(-4);
      return `${firstFive}...${lastFour}`;
    } else {
      return str;
    }
  }
}

export default StringUtils;