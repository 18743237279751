<template>
  <div class="home">
    <div class="balance-container">
      <div class="title-container">
        <div class="name-box">
          <img src="../assets/logo.jpeg">
          <span class="token-name">TORTOISE</span>
        </div>
        <span class="token-address">{{ stringUtils.sliceString(walletAddress) }}</span>
      </div>
      <span class="balance-title">Balance:</span>
      <span class="balance-quantity">{{ walletBalance }}</span>
      <span class="balance-amount">≈ $0.00</span>
    </div>
    <div class="price-container">
      <span class="title">TORTOISE 当前价格</span>
      <span class="price">≈ $0.0{6}1157</span>
    </div>
    <div class="item-container">
      <span v-if="times.days >= 0"
            class="title">释放剩余时间：{{ times.days }}天 {{ times.hours }}小时 {{ times.minutes }}分钟 {{
          times.seconds
        }}秒</span>
      <span v-else class="title">已开始逐步释放</span>
      <span class="deposit-title">Your Balance:</span>
      <div class="deposit-quantity">
        <img src="../assets/logo.jpeg">
        <span>{{ Number(user.amount).toFixed(4) }} TURTLE</span>
      </div>
      <div class="rate-container">
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="font-size: 14px;color: gray">释放比例：</span>
          <span style="color: white; font-size: 14px">5% / Day</span>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="font-size: 14px;color: gray">可提数量：</span>
          <span style="color: #5a38bb; font-size: 14px">{{ Number(availableAmount).toFixed(4) }}</span>
        </div>
      </div>
      <div class="submit-container">
        <span :class="isTimeUp ? 'enable-button':'disable-button'" @click="showDialog=true">提取</span>
      </div>
    </div>
    <div class="item-container" style="padding: 20px 0">
      <span class="title" style="padding: 0 15px">异常数据申报</span>
      <div class="input-area">
        <van-config-provider theme="light">
          <van-cell-group inset>
            <van-field
                v-model="exceptionAddress"
                rows="1"
                autosize
                type="textarea"
                placeholder="请输入异常钱包地址"
            />
          </van-cell-group>
        </van-config-provider>
      </div>
      <div class="submit-btn">
        <van-button :loading="exceptionLoading" loading-text="提交中..." block type="warning"
                    @click="handleSubmitException">提交
        </van-button>
      </div>

    </div>
    <van-action-sheet
        v-model:show="showDialog"
        title="提取TURTLE">
      <div class="deposit-panel">
        <van-cell-group inset class="panel-background">
          <van-field
              v-model="withdrawAmount"
              center
              clearable
              border
              label="提取数量"
              placeholder="请输入提取数量">
            <template #button>
              <van-button
                  size="small"
                  type="primary"
                  @click="withdrawAmount=Number(availableAmount).toFixed(0)">最大
              </van-button>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 10px 0"></div>
        <van-button
            color="#5a38bb"
            round
            :loading="withdrawLoading"
            loading-text="提取中..."
            @click="handleWithdraw">确定提取
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Web3 from "web3";
import WalletUtils from "@/utils/WalletUtils";
import StringUtils from "@/utils/StringUtils";
import 'vant/es/toast/style';
import 'vant/es/action-sheet/style'
import {
  stakingContractABI,
  stakingContractAddress,
  tokenContractABI,
  tokenContractAddress
} from "@/assets/chain/contractDetails";
import {closeToast, showFailToast, showSuccessToast, showToast} from "vant";

export default {
  name: 'HomeView',
  data() {
    return {
      walletAddress: '',
      walletBalance: 0,
      currentPrice: 0,
      stringUtils: new StringUtils(),
      allowance: 0,
      web3: null,
      stakingContractInstance: null,
      tokenContractInstance: null,
      selectedDuration: 0,
      stakingQuantity: 0,
      showAction: false,
      stakingLoading: false,

      startTime: 0,
      isTimeUp: false,
      times: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      user: {
        amount: 0,
        extractAmount: 0,
        lockFlag: false,
        exist: false
      },
      availableAmount: 0,
      withdrawAmount: 0,
      showDialog: false,
      withdrawLoading: false,
      exceptionLoading: false,
      exceptionAddress: undefined,
      data: null,
      error: null
    };
  },
  async mounted() {
    await this.initWeb3();
    await this.getAddress();
    await this.getWalletBalance();
    this.isTimeUp = await this.checkTimeUp();
    this.timeCountDown();
    this.startCountDown();
    await this.getUserInfo();
    await this.getAvailableAmount();
  },
  methods: {
    async initWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        this.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        this.stakingContractInstance = new window.web3.eth.Contract(stakingContractABI, stakingContractAddress);
        this.tokenContractInstance = new window.web3.eth.Contract(tokenContractABI, tokenContractAddress);
      } else {
        console.error("Please install MetaMask!")
      }
    },
    async getAddress() {
      const walletUtils = new WalletUtils();
      if (walletUtils.isBlockchainBrowser()) {
        this.walletAddress = await walletUtils.getWalletAddress();
      } else {
        console.log("非区块链浏览器");
      }
    },
    async getWalletBalance() {
      const walletUtils = new WalletUtils();
      if (this.walletAddress) {
        this.walletBalance = await walletUtils.getTokenBalance(tokenContractAddress, this.walletAddress)
      } else {
        this.walletBalance = 0.00
      }
    },
    async getUserInfo() {
      try {
        const res = await this.stakingContractInstance.methods.users(this.walletAddress).call();
        this.user.amount = this.web3.utils.fromWei(res.amount, 'ether');
        this.user.extractAmount = this.web3.utils.fromWei(res.extractAmount, 'ether');
        this.user.lockFlag = res.lockFlag;
        this.user.exist = res.exist;
      } catch (error) {
        console.error(error);
      }
    },
    async getAvailableAmount() {
      try {
        const res = await this.stakingContractInstance.methods.availableForWithdrawal(this.walletAddress).call();
        this.availableAmount = this.web3.utils.fromWei(res, 'ether');
      } catch (error) {
        console.log(error);
      }
    },

    async handleWithdraw() {
      this.withdrawLoading = true;
      try {
        if (this.withdrawAmount == 0) {
          closeToast();
          this.withdrawLoading = false;
          showToast("提取数量必须大于0");
          return;
        }
        await this.stakingContractInstance.methods.withdraw(this.web3.utils.toWei(this.withdrawAmount, 'ether')).send({from: this.walletAddress});
        closeToast();
        this.withdrawLoading = false;
        showToast("提取成功");
      } catch (error) {
        console.log(error)
        closeToast();
        this.withdrawLoading = false;
        showToast("提取失败");
      }
      await this.getUserInfo();
      await this.getAvailableAmount();
    },

    async getStartTime() {
      try {
        const res = await this.stakingContractInstance.methods.startTime().call();
        this.startTime = Number(res);
      } catch (error) {
        console.log(error);
      }
    },

    getCurrentTime() {
      let now = new Date();
      let seconds = now.getSeconds();
      let tenSeconds = Math.floor(seconds / 10) * 10; // 舍去秒数的个位数
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), tenSeconds, 0).getTime() / 1000;
    },

    async checkTimeUp() {
      await this.getStartTime();
      const current = this.getCurrentTime();
      console.log(current >= this.startTime, current, this.startTime);
      return current >= this.startTime;
    },

    async handleSubmitException() {
      this.exceptionLoading = true;
      if (!this.exceptionAddress) {
        showToast("请输入异常钱包地址");
        this.exceptionLoading = false;
        return;
      }
      if (!this.web3.utils.isAddress(this.exceptionAddress)) {
        this.exceptionLoading = false;
        showToast("请检查你输入的钱包地址");
        return;
      }
      try {
        const response = await this.$http.post('/add_wallet', {address: this.exceptionAddress});
        this.exceptionLoading = false;
        this.data = response.message;
        showSuccessToast("上报成功");
        console.log(this.data, response)
      } catch (err) {
        this.exceptionLoading = false;
        this.error = err.message;
        showFailToast(err.response.data.message);
        console.log(err.message, err.response.data.message)
      }
    },

    timeCountDown() {
      // 假设你有一个未来时间的十位时间戳（单位：秒）
      let futureTenSecondTimestamp = this.startTime;
      let futureMilliseconds = futureTenSecondTimestamp * 1000;
      let futureDate = new Date(futureMilliseconds);
      let now = new Date();
      let diffMilliseconds = futureDate - now;

      this.times.seconds = Math.floor((diffMilliseconds / 1000) % 60);
      this.times.minutes = Math.floor((diffMilliseconds / (1000 * 60)) % 60);
      this.times.hours = Math.floor((diffMilliseconds / (1000 * 60 * 60)) % 24);
      this.times.days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
    },

    startCountDown() {
      setInterval(() => {
        this.timeCountDown();
      }, 1000);
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;

  .balance-container {
    width: 100%;
    background: #5a38bb;
    border-radius: 8px;
    border: none;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    box-sizing: border-box;

    .title-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .name-box {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }

        .token-name {
          color: white;
          font-size: 25px;
          margin-left: 10px;
        }
      }

      .token-address {
        padding: 5px 10px;
        color: white;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #FFFFFF;

      }
    }

    .balance-title {
      font-size: 16px;
      color: gray;
      margin-top: 10px;
    }

    .balance-quantity {
      color: white;
      font-weight: bold;
      font-size: 20px;
    }

    .balance-amount {
      color: gray;
      font-size: 14px;
    }
  }

  .price-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      color: gray;
      font-size: 14px;
    }

    .price {
      color: #5a38bb;
      font-size: 12px;
    }
  }

  .item-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    background-image: linear-gradient(to bottom, #21265c, #17182c);
    border-radius: 10px;

    .title {
      font-size: 18px;
      color: white;
    }

    .input-area {
      width: 100%;
      padding: 20px 0;
      box-sizing: border-box;
    }

    .submit-btn {
      width: 100%;
      padding: 20px 15px;
      box-sizing: border-box;
    }

    .deposit-title {
      padding: 10px 0;
      font-size: 14px;
      color: gray;
    }

    .deposit-quantity {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #FFFFFF;
      }

      span {
        margin-left: 10px;
        color: white;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .rate-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
    }

    .submit-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .enable-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 10px 50px;
        box-sizing: border-box;
        background: #5a38bb;
        border-radius: 40px;
        color: #FFFFFF;
        font-size: 16px;
      }

      .disable-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: 10px 50px;
        box-sizing: border-box;
        border-radius: 40px;
        border: 1px solid #5a38bb;
        color: #5a38bb;
        font-size: 16px;
      }
    }
  }

  .deposit-panel {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}

.panel-background {
  background-color: #5a38bb;
}
</style>
