import axios from 'axios';

const http = axios.create({
    baseURL: 'https://wallet.tortoisecoin.top', // 设置您的API基础URL
    timeout: 10000, // 请求超时时间
});

// 请求拦截器
http.interceptors.request.use(
    config => {
        // 可以在这里添加请求头或者其他操作
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
http.interceptors.response.use(
    response => {
        // 对响应数据进行处理
        const res = response.data;
        if (res.status === 'success') {
            return res;
        } else {
            // 可以统一处理错误信息
            return Promise.reject(new Error(res.message || 'Error'));
        }
    },
    error => {
        return Promise.reject(error);
    }
);

export default http;
